<template>
  <div
    class="text-center"
    style="margin-top: 30%"
  >
    <div class="d-flex flex-column justify-center">
      <h1 class="title-text justify-center mb-10">
        {{ $t('product-finder.success.title') }}
      </h1>
      <v-icon
        color="primary"
        size="200"
      >
        mdi-check-circle
      </v-icon>
      <p class="mt-10">
        {{ $t('product-finder.success.info', {seconds}) }}
      </p>
    </div>
  </div>
</template>
<script>

export default {
  data () {
    return {
      seconds: 4 // Initial countdown value in seconds
    }
  },
  mounted () {
    this.startCountdown()
  },
  methods: {
    startCountdown () {
      const countdownInterval = setInterval(() => {
        if (this.seconds > 0) {
          this.seconds--
        } else {
          clearInterval(countdownInterval)
          window.location.href = window.location.origin + '/dashboard'
        }
      }, 1000)
    }
  }
}

</script>

<style scoped>
.title-text {
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 34px;
}
</style>
